import styled from "styled-components";
import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {ButtonUI, TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";

import srcIconDonut from "@images/elektronniy-koshelek/icon-donut.svg";
import srcIconGift from "@images/elektronniy-koshelek/icon-gift.svg";
import srcIconRuble from "@images/elektronniy-koshelek/icon-ruble.svg";

export const WhyNeedWalletSection = () => (
    <PlatformSectionContainer id={'platformSection'}>
        <PlatformSectionTitle swichPoint={LAPTOP_MOBILE_POINTER}>Зачем мне нужен кошелек</PlatformSectionTitle>

        <PlatformSectionComponent>
            <PlatformSectionComponentDetailItems>
                <PlatformSectionComponentDetailItem>

                    <PlatformSectionComponentDetailItemContent>
                        <PlatformSectionComponentDetailItemElements>
                            <PlatformSectionComponentDetailItemElement>
                                <div className='icon'><img src={srcIconDonut} alt=""/></div>
                                <div>
                                    <TypographyUI.H4Bold className="text">
                                        Собрать донаты от подписчиков
                                    </TypographyUI.H4Bold>
                                    <TypographyUI.Body1Regular className="text">
                                        Вместо того, чтобы публиковать номер карты в соцсетях, — поделитесь ссылкой на свой Кошелёк
                                    </TypographyUI.Body1Regular>
                                </div>
                            </PlatformSectionComponentDetailItemElement>
                            <PlatformSectionComponentDetailItemElement>
                                <div className='icon'><img src={srcIconGift} alt=""/></div>
                                <div>
                                    <TypographyUI.H4Bold className="text">
                                        Получить подарок на День рождения
                                    </TypographyUI.H4Bold>
                                    <TypographyUI.Body1Regular className="text">
                                        Отправьте уникальную ссылку друзьям и родственникам
                                    </TypographyUI.Body1Regular>
                                </div>
                            </PlatformSectionComponentDetailItemElement>
                            <PlatformSectionComponentDetailItemElement>
                                <div className='icon'><img src={srcIconRuble} alt=""/></div>
                                <div>
                                    <TypographyUI.H4Bold className="text">
                                        Скопить деньги на общую цель
                                    </TypographyUI.H4Bold>
                                    <TypographyUI.Body1Regular className="text">
                                        Планируйте вечеринку или поход с друзьями? Используйте отдельную копилку вместе карты
                                    </TypographyUI.Body1Regular>
                                </div>
                            </PlatformSectionComponentDetailItemElement>
                        </PlatformSectionComponentDetailItemElements>
                    </PlatformSectionComponentDetailItemContent>
                    <PlatformSectionComponentDetailItemAdditional>
                        <PlatformSectionComponentDetailItemImage>
                            <StaticImage
                                src="../../../images/elektronniy-koshelek/WhyNeedWalletSectionImage.png"
                                loading="eager"
                                placeholder="none"
                                formats={["auto", "webp"]}
                                layout="constrained"
                                objectFit="contain"
                                alt=""
                            />
                        </PlatformSectionComponentDetailItemImage>
                    </PlatformSectionComponentDetailItemAdditional>

                </PlatformSectionComponentDetailItem>
            </PlatformSectionComponentDetailItems>
        </PlatformSectionComponent>

    </PlatformSectionContainer>
);

const PlatformSectionContainer = styled.section`
    display: flex;
    margin-top: 120px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    flex-direction: column;
    margin-bottom: 100px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        //margin-top: 40px;
        //margin-bottom: 20px;
        margin: 100px -19px 40px;
        //left: -3px;
    }
`;

const PlatformSectionTitle = styled(TypographyUI.H2)`
    width: 916px;
    margin: 0 auto 83px;
    text-align: center;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
        margin: 0 auto 40px;
        padding: 0 20px;
    }
`;

const PlatformSectionComponent = styled.div`
    background: var(--background-main-grey);
    height: 466px;
    width: 100%;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    padding: 62px 73px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        height: 100%;
        flex-direction: column;
        border-radius: 12px;
        padding: 30px 20px;
    }
    @media (max-width: 609px) {
        border-radius: 0;
    }
`;

const PlatformSectionComponentDetailItems = styled.div`
    height: 100%;
    width: 998px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
    }
`;

const PlatformSectionComponentDetailItem = styled.div`
    animation: fadeEffect 1s;
    display: flex;
    height: 100%;
    justify-content: space-between;
    flex-direction: row-reverse;
    gap: 30px;
    align-items: center;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        flex-direction: column;
        gap: 0px;
    }
`;

const PlatformSectionComponentDetailItemContent = styled.div`
    width: 464px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
    }
`;

const PlatformSectionComponentDetailItemElements = styled.div`
    display: flex;
    flex-direction: column;
    gap: 44px;
`;
const PlatformSectionComponentDetailItemElement = styled.div`
    display: flex;
    gap: 24px;
    & .icon {
        flex: none;
        width: 24px;
        height: 24px;
    }
`;

const PlatformSectionComponentDetailItemAdditional = styled.div`
    width: 490px;
    height: 475px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    top: 8px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        top: 0px;
        height: 100%;
        width: 100%;
    }
`;
const PlatformSectionComponentDetailItemImage = styled.div`
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        display: none;
        height: 0;
    }
    
`;
const PlatformSectionComponentDetailItemBtn = styled(ButtonUI)`

`;


