import styled from "styled-components";
import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {ButtonUI, TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";
import {useWindowSize} from "@tools/hooks";


export const WhatServicesSection = () => {

    const {width} = useWindowSize();

    return (
        <WhatServicesSectionContainer>
            <WhatServicesSectionTitleContainer>
                <WhatServicesSectionTitle swichPoint={LAPTOP_MOBILE_POINTER}>Какие сервисы есть в Кошельке</WhatServicesSectionTitle>
            </WhatServicesSectionTitleContainer>

            <WhatServicesSectionItems>

                <WhatServicesSectionItem style={{
                    'width': width && width > LAPTOP_MOBILE_POINTER ? '555px' : '100%',
                    'height': width && width > LAPTOP_MOBILE_POINTER ? '438px' : '100%',
                    'background': 'var(--background-second)'
                }}>
                    <WhatServicesSectionItemInfo>
                        <TypographyUI.H3 className="title">Ссылка-визитка</TypographyUI.H3>
                        <TypographyUI.Body1Regular className="description">При регистрации каждому пользователю присваивается уникальная ссылка-визитка — разместите её в социальных сетях или на сайте для приёма донатов</TypographyUI.Body1Regular>
                    </WhatServicesSectionItemInfo>
                    <WhatServicesSectionItemImage style={{
                        'position': width && width > LAPTOP_MOBILE_POINTER ? 'absolute' : 'relative',
                        'bottom': width && width > LAPTOP_MOBILE_POINTER ? '0' : '15px',
                        'left': width && width > LAPTOP_MOBILE_POINTER ? '0' : '',
                        'right': width && width > LAPTOP_MOBILE_POINTER ? '0' : '',
                        margin: '0 auto',
                        'width': width && width > LAPTOP_MOBILE_POINTER ? '523px' : '100%'
                    }}>
                        <StaticImage
                            src="../../../images/elektronniy-koshelek/WhatServicesSectionImage1.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            layout="constrained"
                            objectFit="contain"
                            alt=""
                        />
                    </WhatServicesSectionItemImage>
                </WhatServicesSectionItem>

                <WhatServicesSectionItem style={{
                    'width': width && width > LAPTOP_MOBILE_POINTER ? '565px' : '100%',
                    'height': width && width > LAPTOP_MOBILE_POINTER ? '438px' : '100%',
                    'background': 'var(--background-main-grey)'
                }}>
                    <WhatServicesSectionItemInfo>
                        <TypographyUI.H3 className="title">Эквайринг</TypographyUI.H3>
                        <TypographyUI.Body1Regular className="description">Получайте деньги для некоммерческих целей на своём сайте</TypographyUI.Body1Regular>
                    </WhatServicesSectionItemInfo>
                    <WhatServicesSectionItemImage style={{
                        'position': width && width > LAPTOP_MOBILE_POINTER ? 'absolute' : 'relative',
                        'bottom': width && width > LAPTOP_MOBILE_POINTER ? '0' : '0',
                        'left': '0',
                        'right': '0',
                        'width': '100%',
                        'maxWidth': width && width > LAPTOP_MOBILE_POINTER ? '391px' : null,
                        'margin': '0 auto'
                    }}>
                        <StaticImage
                            src="../../../images/elektronniy-koshelek/WhatServicesSectionImage2.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            layout="constrained"
                            objectFit="contain"
                            alt=""
                        />
                    </WhatServicesSectionItemImage>
                </WhatServicesSectionItem>

                <WhatServicesSectionItem style={{
                    'width': width && width > LAPTOP_MOBILE_POINTER ? '755px' : '100%',
                    'height': width && width > LAPTOP_MOBILE_POINTER ? '424px' : '100%',
                    'background': 'var(--background-main-grey)'
                }}>
                    <WhatServicesSectionItemInfo style={{'maxWidth': width && width > LAPTOP_MOBILE_POINTER ? '38%' : '100%'}}>
                        <TypographyUI.H3 className="title">Копилка</TypographyUI.H3>
                        <TypographyUI.Body1Regular className="description">Откройте сбор средств на общую цель. Например, копите на совместное путешествие, подарок общему другу или корпоратив</TypographyUI.Body1Regular>
                    </WhatServicesSectionItemInfo>
                    <WhatServicesSectionItemImage style={{
                        'position': width && width > LAPTOP_MOBILE_POINTER ? 'absolute' : 'relative',
                        'bottom': '15px',
                        'right': width && width > LAPTOP_MOBILE_POINTER ? '50px' : '0',
                        'width': width && width > LAPTOP_MOBILE_POINTER ? '391px' : '100%',
                        'padding': width && width > LAPTOP_MOBILE_POINTER ? null : '0',
                    }}>
                        <StaticImage
                            src="../../../images/elektronniy-koshelek/WhatServicesSectionImage3.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            layout="constrained"
                            objectFit="contain"
                            alt=""
                        />
                    </WhatServicesSectionItemImage>
                </WhatServicesSectionItem>

                <WhatServicesSectionItem style={{
                    'width': width && width > LAPTOP_MOBILE_POINTER ? '365px' : '100%',
                    'height': width && width > LAPTOP_MOBILE_POINTER ? '424px' : '100%',
                    'background': 'var(--background-second)'
                }}>
                    <WhatServicesSectionItemInfo>
                        <TypographyUI.H3 className="title">Запрос денег</TypographyUI.H3>
                        <TypographyUI.Body1Regular className="description">Создайте новую ссылку с просьбой перевести конкретную сумму денег и отправьте её другу или родственнику</TypographyUI.Body1Regular>
                    </WhatServicesSectionItemInfo>
                    <WhatServicesSectionItemImage style={{
                        'position': width && width > LAPTOP_MOBILE_POINTER ? 'absolute' : 'relative',
                        'bottom': width && width > LAPTOP_MOBILE_POINTER ? '0' : '15px',
                        'left': width && width > LAPTOP_MOBILE_POINTER ? '0' : '0',
                        'right': width && width > LAPTOP_MOBILE_POINTER ? '0' : '0',
                        'width': width && width > LAPTOP_MOBILE_POINTER ? '100%' : '100%'
                    }}>
                        <StaticImage
                            src="../../../images/elektronniy-koshelek/WhatServicesSectionImage4.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            layout="constrained"
                            objectFit="contain"
                            alt=""
                        />
                    </WhatServicesSectionItemImage>
                </WhatServicesSectionItem>

            </WhatServicesSectionItems>

        </WhatServicesSectionContainer>
    )

}

const WhatServicesSectionContainer = styled.section`
    display: flex;
    margin-top: 160px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    flex-direction: column;
    margin-bottom: 100px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin: 100px -19px 60px;
    }
`;

const WhatServicesSectionTitleContainer = styled.div`
    width: 100%;
    margin-bottom: 60px;
    max-width: 830px;
    text-align: center;
    @media (max-width: 609px) {
        padding: 0 20px;
    }
`;

const WhatServicesSectionTitle = styled(TypographyUI.H2)`
    margin-bottom: 24px;
`;


const WhatServicesSectionItems = styled.div`
    width: 100%;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
`;

const WhatServicesSectionItem = styled.div`
    padding-top: 40px;
    padding-left: 28px;
    padding-right: 28px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    @media (max-width: 609px) {
        border-radius: 0px;
    }
`;

const WhatServicesSectionItemInfo = styled.div`
    & .title {
        margin-bottom: 16px;
    }
    & .description {
        color: var(--text-secondary);
    }
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        padding-bottom: 40px;
    }
`;

const WhatServicesSectionItemImage = styled.div`
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 300px;
        margin: 0 auto;
        padding: 20px 0 0;
    }

`;


