import React from "react";
import {Helmet} from "react-helmet";
import {Layout} from "@components";
import {TopSection} from "@templates/elektronniy-koshelek/TopSection";
import {WhyNeedWalletSection} from "@templates/elektronniy-koshelek/WhyNeedWalletSection";
import {WhatServicesSection} from "@templates/elektronniy-koshelek/WhatServicesSection";
import {AdvantagesWalletSection} from "@templates/elektronniy-koshelek/AdvantagesWalletSection";
import {UseWalletSection} from "@templates/elektronniy-koshelek/UseWalletSection";
import {SoonCardsSection} from "@templates/elektronniy-koshelek/SoonCardsSection";
import {DownloadWalletSection} from "@templates/elektronniy-koshelek/DownloadWalletSection";
import {OpenWalletSection} from "@templates/elektronniy-koshelek/OpenWalletSection";


const WalletFlPage = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>
                    Личный электронный кошелек в России 2024 | Открыть электронный кошелек онлайн
                </title>
                <meta
                    name="description"
                    content="В поиске личного электронного кошелька в России в 2024? Открывайте новый электронный кошелек вместе с нами! Удобный сервис для физических лиц"
                />
                <link
                    rel="canonical"
                    href="https://xn--80aapgyievp4gwb.xn--p1ai/elektronniy-koshelek"
                />
            </Helmet>
            <Layout>

                <TopSection/>
                <WhyNeedWalletSection/>
                <WhatServicesSection/>
                <UseWalletSection/>
                <AdvantagesWalletSection/>
                <SoonCardsSection/>
                <DownloadWalletSection/>
                <OpenWalletSection/>

            </Layout>
        </>
    );
};

export default WalletFlPage;
